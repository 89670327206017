define(['app', '$location'], function (app, $location) {
  return function socialLinkModal() {
    const component = {};

    const config = {
      closeButtonSelector: '[data-close]',
      showClass: 'socialLinkModal-show'
    }

    const closeModal = () => {
      component.element.classList.remove(config.showClass);
    }

    const init = (element) => {
      component.element = element;
      component.closeButtons = Array.from(element.querySelectorAll(config.closeButtonSelector));
      component.closeButtons.forEach((button) => {
        button.addEventListener('click', (event) => { event.preventDefault(); closeModal() });
      })
      return component;
    }

    component.init = init;
    component.config = config;
    component.closeModal = closeModal;

    return component;
  };
})
